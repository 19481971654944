import React from 'react';
import TweenOne from 'rc-tween-one';

import { MDBCard, MDBCardBody, MDBMask,MDBCol,MDBRow,MDBCarousel,MDBContainer,MDBCarouselInner,MDBCarouselItem,MDBView,MDBCarouselCaption} from "mdbreact";



import Footer0 from '../Home/Footer0';
import '../Home/static/style';

import {Footer00DataSource,} from '../Home/data.source';

import {Form, Carousel} from 'antd';
import { enquireScreen } from 'enquire-js';

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import api from "../services/api";
import {iUrl} from "../../constants/global";
import {
  collection,
  doc,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import {  db as firestore } from "../../constants/firebase";

import Itemz from "../../components/Item"

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}
const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class Banner1 extends React.PureComponent {
  state = {
    st: "",
    mc:"white",
    services:[]
   
  }
  componentDidMount(){
    // alert("foo")
    // this.fetchServices();
    
  this.fServices();
  
  }
  
fServices=()=>{
  var context=this;
  const janaRef = collection(firestore, process.env.REACT_APP_NAME);
  
  const projectRef = doc(janaRef, "services");
  const pref = collection(projectRef, "one");

  const q = query(pref, orderBy("uploadDate", "desc"));

  onSnapshot(q, (querySnapshot) => {
    const services = [];
    querySnapshot.forEach((doc) => {
      const service = {
        id: doc.id,
        ...doc.data(),
      };
      services.push(service);
    });
    context.setState({
      services:services
     })
  });
}

async fetchServices() {      
   await api.get('service').then((response) => {
        // console.log("response: "+ JSON.stringify(response));
        const myData = response.data
      if(myData.val==2){
            console.log(myData)
              // this.companies = myData.data
              this.setState({
                services:myData.data
              })
          }
          console.log("services: "+JSON.stringify(myData))
  }).catch(function (response) {
            //handle error
            console.log("error"+response)
        });
        
      }

  render() {

    return (
      <div  style={{height:this.state.st}}>
   

<MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"172px",paddingBottom:"72px", backgroundColor:"#09141d"}} className="text-center">
    <MDBCardBody style={smobi}>

  </MDBCardBody>
  </MDBCard> 

<MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",paddingBottom:"72px", }} className="text-center">
    <MDBCardBody style={smobi}>
 
<MDBCard style={{ width:"100%",maxWidth: "1200px",paddingBottom:"72px",marginRight: "auto",marginLeft: "auto",marginTop:"-72px", }} className="text-center">
    <MDBCardBody style={smobi}>
 
    <h5 style={{}}> Services</h5>

      <MDBContainer>
        <MDBRow>   
        <Itemz data={this.state.services}/> 
                  
        </MDBRow>
        {/* {this.bgImg("h")} */}
      </MDBContainer>
    </MDBCardBody>
  </MDBCard> 
  
  
    
  </MDBCardBody>
  </MDBCard> 
 
{/* 
    <img style={{width:"120px",borderRadius:"50%"}} src="http://lmgexhibitions.kilaqitu.com/img/a.gif" alt="img" />
     */}
    {/* <h5 type="mask-bottom" style={{color:this.state.mc}}>The best at events and exhibitions</h5> */}
    
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
        </TweenOne>


        <Footer0 style={{ background: "-moz-linear-gradient(315deg, #0cbaba 0%, #380036 74%)",background: "linear-gradient(315deg, #0cbaba 0%, #380036 74%)",}}
      
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />
      </div>
    );
  }
}
const Banner = Form.create({ name: 'time_related_controls' })(Banner1);
export default Banner;
