import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";

import home from "./larsen/Home/index";
import about from "./larsen/about";
import samples from "./larsen/projects/samples";
import products from "./larsen/products";
import service from "./larsen/service";
import contact from "./larsen/contact";

class Routes extends React.Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={home} />
          <Route exact path="/about" component={about} />
          <Route exact path="/samples" component={samples} />
          <Route exact path="/products" component={products} />
          <Route exact path="/services" component={service} />
          <Route exact path="/contact" component={contact} />
          <Route
            render={function () {
              return <Route exact path="/" component={home} />;
            }}
          />
        </Switch>
      </HashRouter>
    );
  }
}

export default Routes;
