import React from 'react';
import TweenOne from 'rc-tween-one';

import { MDBCard, MDBCardBody, MDBMask,MDBCol,MDBRow,MDBCarousel,MDBContainer,MDBCarouselInner,MDBCarouselItem,MDBView,MDBCarouselCaption} from "mdbreact";



import Footer0 from '../Home/Footer0';
import '../Home/static/style';

import {Footer00DataSource,} from '../Home/data.source';
import {Form, Input,Radio} from 'antd';
import { enquireScreen } from 'enquire-js';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import api from "../services/api";
import {
  collection,
  doc,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import {  db as firestore } from "../../constants/firebase";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}
const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class Banner1 extends React.PureComponent {
  state = {
    st: "",
    mc:"white",
    projects:[]
   
  }
  componentDidMount(){
    // alert("foo")
    // this.fetchProjects();
    this.fProjects();
  
  }
  
fProjects=()=>{
  const janaRef = collection(firestore,  process.env.REACT_APP_NAME);
  const projectRef = doc(janaRef, "projects");
  const pref = collection(projectRef, "one");

  const q = query(pref, orderBy("uploadDate", "asc"));

  onSnapshot(q, (querySnapshot) => {
    const projects = [];
    querySnapshot.forEach((doc) => {
      const project = {
        id: doc.id,
        ...doc.data(),
      };
      projects.push(project);
    });
    this.setState({
      projects:projects
    })
  });
}
async fetchProjects() {

  this.products=[];
      
   await api.get('project').then((response) => {
        // console.log("response: "+ JSON.stringify(response));
        const myData = response.data
      if(myData.val==2){
            console.log(myData)
              // this.companies = myData.data
              this.setState({
                projects:myData.data
              })
          }
          console.log("projects: "+JSON.stringify(myData))
     
  }).catch(function (response) {
            //handle error
            console.log("error"+response)
        });
        
      }
 
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    const { getFieldDecorator,} = this.props.form;
    // delete currentProps.isMobile;
    if(currentProps.isMobile){
   
this.setState({
  mc:"black",

})
    }
    return (
      <div  style={{height:this.state.st}}>
   

<MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"172px",paddingBottom:"72px", backgroundColor:"#09141d"}} className="text-center">
    <MDBCardBody style={smobi}>

  </MDBCardBody>
  </MDBCard> 

<MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",paddingBottom:"72px", }} className="text-center">
    <MDBCardBody style={smobi}>
 
<MDBCard style={{ width:"100%",maxWidth: "1200px",paddingBottom:"72px",marginRight: "auto",marginLeft: "auto",marginTop:"-72px", }} className="text-center">
    <MDBCardBody style={smobi}>
 
    <h5 style={{}}>Sample projects</h5>

      <MDBContainer>
        <MDBRow>   
        {this.state.projects.map(function(p, i){
                    return (
                      <MDBCol md="3">
                      <MDBCard color="white lighten-1" style={{ maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                      {/* <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={p1}  /> */}
                          <MDBCardBody style={{color:"#697b8c"}}>
                            {/* <h5 style={{}}>Design</h5> */}
                            <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} > {p.description} </p>
                          
                            <br/>
                            <p style={{}}>{p.duration}</p>
                          </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                    )      
                  })}   
                  
        </MDBRow>
        
      </MDBContainer>
    </MDBCardBody>
  </MDBCard> 
  
  
 
  </MDBCardBody>
  </MDBCard> 
 
{/* 
    <img style={{width:"120px",borderRadius:"50%"}} src="http://lmgexhibitions.kilaqitu.com/img/a.gif" alt="img" />
     */}
    {/* <h5 type="mask-bottom" style={{color:this.state.mc}}>The best at events and exhibitions</h5> */}
    
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
        </TweenOne>


        <Footer0 style={{ background: "-moz-linear-gradient(315deg, #0cbaba 0%, #380036 74%)",background: "linear-gradient(315deg, #0cbaba 0%, #380036 74%)",}}
      
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />
      </div>
    );
  }
}
const Banner = Form.create({ name: 'time_related_controls' })(Banner1);
export default Banner;
