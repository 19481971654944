import React from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBContainer,  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView, MDBMask} from 'mdbreact';

import { Carousel } from 'antd';
import ImageCarousel from './carousel';

const Item = ({ data }) => {
  return (
    <>
      {data.map((p, i) => (
        <MDBCol md="3" key={i}>
          <MDBCard
            color="white lighten-1"
            style={{
              maxWidth: "700px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "32px",
            }}
            text="black"
            className="text-center"
          >
            <MDBContainer>
            {/* <MDBCarousel
      activeItem={1}
      // length={3}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
         {p.downloadURLs.map((image, i) => (
        <MDBCarouselItem itemId={p.id+i}>
          <MDBView>
            <img
              className="d-block w-100"
              src={image}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
    
        </MDBCarouselItem>
         ))}
         </MDBCarouselInner>
         </MDBCarousel> */}
         
              <Carousel autoplay>
                {p.downloadURLs.map((image, j) => (
                  <div key={p.id+j}>
                    {/* <div
                      style={{
                        height: "40vh",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundAttachment: "fixed",
                        backgroundPosition: "center",
                        backgroundImage: `url(${image})`,
                      }}
                    ></div> */}
                    <img
              className="d-block w-100"
              src={image}
              style={{maxHeight: "40vh",}}
              alt="First slide"
            />
                    {/* <p>{p.id + j}</p> */}
                  </div>
                ))}
              </Carousel>
              {/* <ImageCarousel  images={(p.downloadURLs)}/> */}
            </MDBContainer>

            <MDBCardBody style={{ color: "#697b8c" }}>
              <p style={{ whiteSpace: "pre-wrap", marginTop: "22px" }}>
                {p.description}
              </p>
              <br />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ))}
    </>
  );
};

export default Item;
