import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
} from "mdbreact";

import { HashRouter as Router } from "react-router-dom"; // Use HashRouter
import Routes from "./Routes";
import { enquireScreen } from "enquire-js";
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  state = {
    collapseID: "",
    redirect: true,
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  closeCollapse = (collapseID) => () => {
    window.scrollTo(0, 0);
    this.state.collapseID === collapseID && this.setState({ collapseID: "" });
  };

  render() {
    const container = { height: 1300 };
    const { redirect } = this.state;

    return (
      <Router>
        <div className="flyout" style={{ backgroundColor: "#fff", fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }}>
          <MDBNavbar style={{ paddingTop: "1px", paddingBottom: "1px", background: "black", background: "linear-gradient(147deg, rgb(9 20 29) 15%, rgba(0, 0, 0, 0.84) 4%, rgba(0, 0, 0, 0.84) 87%, rgb(9 20 29) 4%)", height: "52px" }} dark expand="md" fixed="top" scrolling>
            <MDBNavbarBrand href="/" style={{ paddingTop: "7px", paddingBottom: "7px", color: "white" }}>
              JEGS
            </MDBNavbarBrand>
            <MDBNavbarToggler className="" icon="location-arrow" onClick={this.toggleCollapse("mainNavbarCollapse")}>
              <MDBIcon icon="ellipsis-v" />
            </MDBNavbarToggler>
            {isMobile ? (
              <MDBCollapse
                id="mainNavbarCollapse"
                isOpen={this.state.collapseID}
                navbar
                className="dark-grey-text"
                style={{ backgroundColor: "black" }}
              >
                <MDBNavbarNav left onClick={this.toggleCollapse("mainNavbarCollapse")}>
                  <MDBNavItem active>
                    <MDBNavLink to="/#/">Home</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/samples">Products</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/services">Services</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/samples">Sample projects</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/about">About</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/contact">Contact us</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                </MDBNavbarNav>
                <MDBNavbarNav right>
                  <MDBNavItem>
                    <MDBNavLink to="#">
                      <MDBIcon fab icon="facebook-f" />
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#">
                      <MDBIcon fab icon="twitter" />
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#">
                      <MDBIcon fab icon="instagram" />
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            ) : (
              <MDBCollapse
                id="mainNavbarCollapse"
                isOpen={this.state.collapseID}
                navbar
                className="dark-grey-text"
                style={{}}
              >
                <MDBNavbarNav left onClick={this.toggleCollapse("mainNavbarCollapse")} style={{ marginLeft: "90px" }}>
                  <MDBNavItem active style={{ marginLeft: "90px" }}>
                    <MDBNavLink to="/#/">Home</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem style={{ marginLeft: "40px" }}>
                    <MDBNavLink to="/products">Products</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem style={{ marginLeft: "40px" }}>
                    <MDBNavLink to="/services">Services</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem style={{ marginLeft: "40px" }}>
                    <MDBNavLink to="/samples">Sample projects</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem style={{ marginLeft: "40px" }}>
                    <MDBNavLink to="/about">About</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                  <MDBNavItem style={{ marginLeft: "40px" }}>
                    <MDBNavLink to="/contact">Contact us</MDBNavLink> {/* Add '#' */}
                  </MDBNavItem>
                </MDBNavbarNav>
                <MDBNavbarNav right>
                  <MDBNavItem>
                    <MDBNavLink to="#">
                      <MDBIcon fab icon="facebook-f" />
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#">
                      <MDBIcon fab icon="twitter" />
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#">
                      <MDBIcon fab icon="instagram" />
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            )}
          </MDBNavbar>
          <div style={{}}>
            <main>
              <Routes />
            </main>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
