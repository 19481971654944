import React from 'react';
import TweenOne from 'rc-tween-one';

import { MDBCard, MDBCardBody, MDBMask,MDBCol,MDBRow,MDBCarousel,MDBContainer,MDBCarouselInner,MDBCarouselItem,MDBView,MDBCarouselCaption} from "mdbreact";



import Footer0 from '../Home/Footer0';
import '../Home/static/style';

import {Footer00DataSource,} from '../Home/data.source';

import {Form, Carousel} from 'antd';
import { enquireScreen } from 'enquire-js';

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import api from "../services/api";
import {iUrl} from "../../constants/global";

import {
  collection,
  doc,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import {  db as firestore } from "../../constants/firebase";
import Itemz from "../../components/Item"

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}
const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class Banner1 extends React.PureComponent {
  state = {
    st: "",
    mc:"white",
    products:[]
   
  }
  componentDidMount(){
    // alert("foo")
    this.fProducts();
  
  }
  
fProducts=()=>{
  var context=this;
  const janaRef = collection(firestore, process.env.REACT_APP_NAME);
  const projectRef = doc(janaRef, "products");
  const pref = collection(projectRef, "one");

  const q = query(pref, orderBy("uploadDate", "asc"));

  onSnapshot(q, (querySnapshot) => {
    const products = [];
    querySnapshot.forEach((doc) => {
      const product = {
        id: doc.id,
        ...doc.data(),
      };
      products.push(product);
    });
    // this.products = products;
    context.setState({
      products:products
     })
    //  console.log(JSON.stringify(products))
  });
}
async fetchProducts() {      
   await api.get('product').then((response) => {
        // console.log("response: "+ JSON.stringify(response));
        const myData = response.data
      if(myData.val==2){
            console.log(myData)
              // this.companies = myData.data
              this.setState({
                products:myData.data
              })
          }
          console.log("products: "+JSON.stringify(myData))
  }).catch(function (response) {
            //handle error
            console.log("error"+response)
        });
        
      }

      bgImg=(u)=>{
        let r1bg2;
r1bg2={height:"40vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+u+")"}
  if(isMobile){
    r1bg2={height:"40vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+u+")"}
  }
return (<div style={r1bg2}></div>);
}
 
imageD(u){
return (<div >{u}</div>)
}
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    const { getFieldDecorator,} = this.props.form;
    // delete currentProps.isMobile;
    if(currentProps.isMobile){
   
this.setState({
  mc:"black",

})
    }
    return (
      <div  style={{height:this.state.st}}>
   

<MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"172px",paddingBottom:"72px", backgroundColor:"#09141d"}} className="text-center">
    <MDBCardBody style={smobi}>

  </MDBCardBody>
  </MDBCard> 

<MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",paddingBottom:"72px", }} className="text-center">
    <MDBCardBody style={smobi}>
 
<MDBCard style={{ width:"100%",maxWidth: "1200px",paddingBottom:"72px",marginRight: "auto",marginLeft: "auto",marginTop:"-72px", }} className="text-center">
    <MDBCardBody style={smobi}>
 
    <h5 style={{}}> Products</h5>

      <MDBContainer>
        <MDBRow>   
       
  <Itemz data={this.state.products}/>
                  
        </MDBRow>
        {/* {this.bgImg("h")} */}
      </MDBContainer>
    </MDBCardBody>
  </MDBCard> 
  
  
 

  {/* <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",paddingBottom:"72px",marginTop:"73px", }} className="text-center">
    <MDBCardBody  style={smobi}>
    <h5 style={{paddingBottom:"33px"}}>Projects</h5>
    
  <Carousel   
  swipeable={false}
  draggable={false}
  showDots={false}
  responsive={responsive1}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="all .5"
  arrows={false}
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile","co"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-20-px">
     <div>
            <img className="img-fluid" style={{width:"99%",maxWidth: "187px"}} src={g1}  />
        
            </div>
            <div>  
            <img className="img-fluid" style={{width:"99%",maxWidth: "187px"}} src={g2}  />
           
            </div>
            <div>  
            <img className="img-fluid" style={{width:"99%",maxWidth: "187px"}} src={g3}  />
        
            </div>
            <div>  
            <img className="img-fluid" style={{width:"99%",maxWidth: "187px"}} src={g4}  />
          
            </div>
</Carousel>

      </MDBCardBody>
    </MDBCard> */}
    
  </MDBCardBody>
  </MDBCard> 
 
{/* 
    <img style={{width:"120px",borderRadius:"50%"}} src="http://lmgexhibitions.kilaqitu.com/img/a.gif" alt="img" />
     */}
    {/* <h5 type="mask-bottom" style={{color:this.state.mc}}>The best at events and exhibitions</h5> */}
    
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
        </TweenOne>


        <Footer0 style={{ background: "-moz-linear-gradient(315deg, #0cbaba 0%, #380036 74%)",background: "linear-gradient(315deg, #0cbaba 0%, #380036 74%)",}}
      
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />
      </div>
    );
  }
}
const Banner = Form.create({ name: 'time_related_controls' })(Banner1);
export default Banner;
