import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage, } from "firebase/storage";
import { getAuth } from "firebase/auth";


const config = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(config)
export const db = getFirestore(app)
export const storage = getStorage(app);
export const auth = getAuth(app);
// export default firebase
export default app
